import React, {Component} from 'react';
import Header from '../Components/Header/Header';
import Hero from '../Components/Hero/Hero';

import Footer from '../Components/Footer/Footer';
import NscSix from '../Components/Nsc-six/Nsc-six';

import LogoWS from '../img/logo-wallstreet.jpg';
import LogoTE from '../img/logo-theeconomist.jpg';
import LogoBB from '../img/logo-BB.jpg';
import LogoFT from '../img/logo-FT.jpg';
import LogoCNN from '../img/logo-CNN.jpg';
import LogoHA from '../img/logo-haver.jpg';


class News extends Component {

    constructor(...props) {
        super(...props);
        this.state = {}
    }

    render() {
        return (

            <div>
                <div className="container-fluid px-0">

                    <div className="row mx-0">
                        <div className="col-12 px-0">
                            <Header/>
                        </div>
                    </div>

                    <div className="container">

                    <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                               
                                <br></br>
                                <br></br>

                            <div className="col-12 col-lg-12">
                            <div className='row'>
                                
                                <p>
                                    <strong>NSC ASESORES, S.C., ASESOR EN INVERSIONES INDEPENDIENTE&nbsp;</strong>
                                    tiene el número de folio de inscripción&nbsp;
                                    <strong>30001-001-(13832)-17/07/2015</strong>&nbsp;
                                                                                                                    en el registro de Asesores en inversiones asignado por la Comisión Nacional Bancaria y de Valores (“La Comisión”).
                                </p>
                                <p>
                                    La Comisión supervisa exclusivamente la prestación de servicios de administración de cartera de valores cuando se tomen decisiones de inversión a nombre y por cuenta de terceros, así como los servicios consistentes en otorgar asesoría de inversión en Valores, análisis y emisión de recomendaciones de inversión de manera individualizada, por lo que carece de atribuciones para supervisar o regular cualquier otro servicio que proporcione el Asesor en inversiones.
                                </p>
                                <p>
                                    Asimismo, la inscripción en el registro de Asesores en inversiones que lleva la Comisión en términos de la Ley del Mercado de Valores, no implica el apego de los Asesores en inversiones a las disposiciones aplicables en los servicios prestados, ni la exactitud o veracidad de la información proporcionada.
                                </p>
                               
                                </div>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                        </div>
                    </div>

                    {/*
          <div className="row mx-0">
            <div className="col-12 px-0">
              <Header />
            </div>
          </div>

          <Hero
            page="-video"
            content="" />


          <div className="container">
            <div className="row my-50 mx-0 d-flex justify-content-center">
              <div className="col-12 col-lg-8">
                <iframe title="1" width="100%" height="315" src="https://www.youtube.com/embed/FCwEzdlEFpM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" al={"true"} lowfullscreen={"true"}></iframe>
                <p className="title">Menor producción de crudo</p>
                <p className="mb-30">La caída en la producción de crudo está impactando en los ingresos petroleros, y si no se revierte se dificultará el cumplimiento de las metas fiscales para el presente año y podría propiciar nuevos recortes al gasto.</p>
              </div>
              <div className="row mt-50 mx-0 d-flex justify-content-center">
                <div className="col-12 col-md-3">
                  <iframe title="2" className="w-100" src="https://www.youtube.com/embed/f_c1QCPDlfE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" al={"true"} lowfullscreen={"true"}></iframe>
                </div>
                <div className="col-12 col-md-9 d-flex flex-column justify-content-center">
                  <p className="title">¿Cómo funciona la bolsa mexicana de valores?</p>
                  <p className="mb-30">Te haz preguntado, ¿Como funciona la BMV, que es, y como se invierte? en este video te queremos trasmitir la idea, de lo que es, sus funciones y regulaciones de la Bolsa de Valores de México.</p>
                </div>
              </div>
              <div className="row mt-50 mx-0 d-flex justify-content-center">
                <div className="col-12 col-md-3">
                  <iframe title="3" className="w-100" src="https://www.youtube.com/embed/skK1-4Ss6bI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" al={"true"} lowfullscreen={"true"}></iframe>
                </div>
                <div className="col-12 col-md-9 d-flex flex-column justify-content-center">
                  <p className="title">Introducción al Mundo de las Inversiones</p>
                  <p className="mb-30">Aprende sobre el mundo de las inversiones, descubre sus beneficios y aplica estos conocimientos para ser un elemento...</p>
                </div>
              </div>
              <div className="row mt-50 mx-0 d-flex justify-content-center">
                <div className="col-12 col-md-3">
                  <iframe title="4" className="w-100" src="https://www.youtube.com/embed/JCMYjez93pk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" al={"true"} lowfullscreen={"true"}></iframe>
                </div>
                <div className="col-12 col-md-9 d-flex flex-column justify-content-center">
                  <p className="title">¿Cómo prepararte para una recesión económica?</p>
                  <p className="mb-30">Descubre los pasos necesarios para preparate en caso de una crisis económica mundial, no dejes que este tipo de situaciones...</p>
                </div>
              </div>
            </div>
          </div >

          <div className="container my-100">
            <div className="row">
              <div className="col-12">
                <p className="title d-flex justify-content-center">Fuentes Financieras</p>
              </div>
            </div>


            <div className="row">
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoWS} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoTE} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoFT} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoHA} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoCNN} alt="Wall Street Journal Logo" />
              </div>
              <div className="col-4 col-lg-2">
                <img className="w-100" src={LogoBB} alt="Wall Street Journal Logo" />
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row my-100">
              <div className="col-12">
                <p className="title text-center">Las 6 de NSC</p>
                <p className="text-center">Entérate de las últimas y más relevantes noticias de ámbito financiero.</p>
              </div>
            </div>
            <div className="row mx-0 mb-30">
              <div className="col-12">
                <NscSix />
              </div>
            </div>
          </div>

*/}


                        
          <div className="container">
            <div className="row my-100">
              <div className="col-12">
                <p className="title text-center"></p>
             
              </div>
            </div>
            <div className="row mx-0 mb-30">
              <div className="col-12">
                
              </div>
            </div>
          </div>


                    <Footer/>
                </div>
            </div>
        );
    }
}

export default News;
